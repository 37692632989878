import React, { useEffect, useState } from "react";
import Form, {
  Item,
  Label,
  ButtonItem,
  ButtonOptions,
} from "devextreme-react/form";
import LoadIndicator from "devextreme-react/load-indicator";
import "devextreme/dist/css/dx.light.css";
import { FileUploader, SelectBox, Popup } from "devextreme-react";
import Button from "@mui/material/Button";
import { baseAxios } from "../../utils/config";
import notify from "devextreme/ui/notify";
import Validator, { RequiredRule, RangeRule } from "devextreme-react/validator";

export const ProductForm = ({
  formData,
  loading,
  setLoading,
  isEdit = false,
  currImage,
  manufacturers,
  setManufacturers,
  categories,
  setCategories,
  brands,
  setBrands,
}) => {
  const [showBrandSelector, setShowBrandSelector] = useState(false);
  const [showCategorySelector, setShowCategorySelector] = useState(false);

  const [filteredBrands, setFilteredBrands] = useState();
  const [filteredCategories, setFilteredCategories] = useState();
  const [image, setImage] = useState();
  const [imageFile, setImageFile] = useState();

  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [itemName, setItemName] = useState([]);
  const [itemId, setItemId] = useState();
  const [newItem, setNewItem] = useState("");

  const [manufacturerId, setManufacturerId] = useState();
  const [brandId, setBrandId] = useState();
  const [categoryId, setCategoryId] = useState();

  const [modalType, setModalType] = useState();

  useEffect(() => {
    if (currImage) setImage(currImage);
  }, [currImage]);

  useEffect(() => {
    if (!imageFile) {
      setImage(null);
      return;
    }
    const objectUrl = URL.createObjectURL(imageFile);
    setImage(objectUrl);

    return () => URL.revokeObjectURL(objectUrl);
  }, [imageFile]);

  useEffect(() => {
    setFilteredBrands(
      formData.current.manufacturerId
        ? brands.filter(
            (x) => x.manufacturer_id == formData.current.manufacturerId
          )
        : brands
    );
    setShowBrandSelector(!!formData.current.manufacturerId);
    setFilteredCategories(
      formData.current.brandId
        ? categories.filter((x) => x.brand_id == formData.current.brandId)
        : categories
    );
    setShowCategorySelector(!!formData.current.brandId);

    setManufacturerId(formData.current.manufacturerId);
    setBrandId(formData.current.brandId);
    setCategoryId(formData.current.categoryId);
  }, [formData.current]);

  const fieldDataChanged = (e) => {
    console.log("Field data changed");
    if (e.dataField === "productImage") {
      if (formData.current.productImage) {
        setImageFile(formData.current.productImage[0]);
      } else {
        setImageFile(null);
      }
    } else if (e.dataField === "manufacturerId") {
      console.log(e.value);
      console.log(brands);
      console.log(brands.filter((x) => x.manufacturer_id == e.value));
      setFilteredBrands(
        e.value ? brands.filter((x) => x.manufacturer_id == e.value) : brands
      );
      setFilteredCategories([]);
      if (e.value) {
        setShowBrandSelector(true);
        setShowCategorySelector(false);
      }
      setManufacturerId(e.value);
      formData.current.manufacturerId = e.value;
      formData.current.brandId = null;
      formData.current.categoryId = null;
    } else if (e.dataField === "brandId") {
      console.log("this?");
      setFilteredCategories(
        e.value ? categories.filter((x) => x.brand_id == e.value) : categories
      );
      if (e.value) {
        setShowCategorySelector(true);
      }
      setBrandId(e.value);
      formData.current.brandId = e.value;
      formData.current.categoryId = null;
    } else if (e.dataField === "categoryId") {
      setCategoryId(e.value);
      formData.current.categoryId = e.value;
    }
  };

  const handleRemoveImage = () => {
    formData.current.productImage = null;
    formData.current.imageDeleted = !!image;
    setImageFile(null);
  };

  const handleConfirm = async () => {
    try {
      if (modalType === "Manufacturer") {
        await handleManufacturer();
      } else if (modalType === "Brand") {
        await handleBrand();
      } else if (modalType === "Category") {
        await handleCategory();
      }
    } catch (e) {
      console.log(e);
      if (e.data.message) {
        notify(e.data.message, "Error", 3000);
      } else {
        notify("Error creating manufacturer", "Error", 3000);
      }
    } finally {
      setNewItem("");
      setItemId();
      setIsPopupVisible(false);
      setModalType("");
    }
  };

  const handleManufacturer = async () => {
    if (itemId) {
      const res = await baseAxios.put(`/products/manufacturers/${itemId}`, {
        name: newItem,
      });
    } else {
      const res = await baseAxios.post("/products/manufacturers", {
        name: newItem,
      });
    }

    const resMan = await baseAxios.get(`/products/manufacturers/all`);
    setManufacturers(resMan.data);
    formData.current.manufacturerId = resMan.data.find(
      (x) => x.manufacturer_name === newItem
    )?.manufacturer_id;
    setManufacturerId(formData.current.manufacturerId);
  };

  const handleBrand = async () => {
    if (itemId) {
      const res = await baseAxios.put(`/products/brands/${itemId}`, {
        name: newItem,
      });
    } else {
      const res = await baseAxios.post("/products/brands", {
        name: newItem,
        manufacturerId: formData.current.manufacturerId,
      });
    }

    const resMan = await baseAxios.get(`/products/brands/all`);
    setBrands(resMan.data);

    setFilteredBrands(
      formData.current.manufacturerId
        ? resMan.data.filter(
            (x) => x.manufacturer_id == formData.current.manufacturerId
          )
        : resMan.data
    );
    formData.current.brandId = resMan.data.find(
      (x) => x.brand_name === newItem
    )?.brand_id;
    setBrandId(formData.current.brandId);
    setFilteredCategories([]);
  };

  const handleCategory = async () => {
    if (itemId) {
      const res = await baseAxios.put(`/products/categories/${itemId}`, {
        name: newItem,
      });
    } else {
      const res = await baseAxios.post("/products/categories", {
        name: newItem,
        brandId: formData.current.brandId,
      });
    }

    const resMan = await baseAxios.get(`/products/categories/all`);
    setCategories(resMan.data);
    setFilteredCategories(
      formData.current.brandId
        ? resMan.data.filter((x) => x.brand_id == formData.current.brandId)
        : resMan.data
    );
    formData.current.categoryId = resMan.data.find(
      (x) => x.category_name === newItem
    )?.category_id;
    setCategoryId(formData.current.categoryId);
  };

  const handleCancel = () => {
    setIsPopupVisible(false);
    setModalType("");
  };

  const handleAddNew = (e, type) => {
    setIsPopupVisible(true);
    setModalType(type);
    e.stopPropagation();
  };

  const handleEdit = (e, id, name, type) => {
    setItemId(id);
    setNewItem(name);
    setIsPopupVisible(true);
    setModalType(type);
  };

  const nameEditorOptions = {
    stylingMode: "filled",
    placeholder: "Color number",
  };
  const manufacturerEditorOptions = {
    items: manufacturers,
    valueExpr: "manufacturer_id",
    displayExpr: "manufacturer_name",
    searchEnabled: true,
    placeholder: "Manufacturer",
  };
  const brandEditorOptions = {
    items: filteredBrands,
    valueExpr: "brand_id",
    displayExpr: "brand_name",
    searchEnabled: true,
    placeholder: "Brand",
  };
  const barcodeEditorOptions = {
    stylingMode: "filled",
    placeholder: "Barcode",
  };
  const categoryEditorOptions = {
    items: filteredCategories,
    valueExpr: "category_id",
    displayExpr: "category_name",
    searchEnabled: true,
    placeholder: "Category",
  };
  const sizeEditorOptions = {
    placeholder: "Oz. Size",
    min: 0, // Ensuring the value can't be negative
    type: "dxNumberBox",
  };
  const priceEditorOptions = {
    placeholder: "Price",
    min: 0, // Ensuring the value can't be negative
    format: "$ #0.##",
    type: "dxNumberBox",
  };

  return (
    <>
      <Popup
        visible={isPopupVisible}
        onHiding={handleCancel}
        dragEnabled={true}
        closeOnOutsideClick={true}
        showTitle={true}
        title={itemId ? "Edit Manufacturer" : "Add Manufacturer"}
        showCloseButton={true}
        width={"30%"}
        height={"auto"}
      >
        <div style={{ display: "flex", flexDirection: "column" }}>
          <input
            style={{ height: "50px", marginInline: "2px" }}
            value={newItem}
            onChange={(e) => setNewItem(e.currentTarget.value)}
            placeholder="Name"
          />
          <Button style={{ marginTop: "30px" }} onClick={handleConfirm}>
            Confirm
          </Button>
        </div>
      </Popup>
      <Form
        encType={"multipart/form-data"}
        formData={formData.current}
        onFieldDataChanged={fieldDataChanged}
        disabled={loading}
        validationGroup="data"
      >
        <Item
          dataField={"name"}
          editorType={"dxTextBox"}
          editorOptions={nameEditorOptions}
        >
          <Label visible={false} />
          <RequiredRule message={"Please choose a product color number"} />
        </Item>
        <Item
          render={(props) => {
            return (
              <SelectBox
                items={[
                  { isNew: true, enableActiveState: false },
                  ...props.editorOptions.items,
                ]}
                valueExpr={"manufacturer_id"}
                displayExpr={"manufacturer_name"}
                value={manufacturerId}
                onItemClick={(e) =>
                  fieldDataChanged({
                    dataField: "manufacturerId",
                    value: e.itemData.manufacturer_id,
                  })
                }
                itemRender={(data) => {
                  return !data.isNew ? (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <div>{data.manufacturer_name}</div>
                      <Button
                        onClick={(e) =>
                          handleEdit(
                            e,
                            data.manufacturer_id,
                            data.manufacturer_name,
                            "Manufacturer"
                          )
                        }
                      >
                        Edit
                      </Button>
                    </div>
                  ) : (
                    <Button
                      style={{
                        width: "100%",
                        height: "100%",
                        margin: "-7px -9px",
                      }}
                      onClick={(e) => handleAddNew(e, "Manufacturer")}
                    >
                      Add New
                    </Button>
                  );
                }}
              >
                <Validator validationGroup="data">
                  <RequiredRule message={"Required"} />
                </Validator>
              </SelectBox>
            );
          }}
          dataField={"manufacturerId"}
          editorType={"dxSelectBox"}
          editorOptions={manufacturerEditorOptions}
        >
          <Label visible={false} />
          <RequiredRule message={"Please choose a manufacturer name"} />
        </Item>
        {showBrandSelector && (
          <Item
            render={(props) => {
              return (
                <SelectBox
                  valueExpr={"brand_id"}
                  displayExpr={"brand_name"}
                  value={brandId}
                  onItemClick={(e) =>
                    fieldDataChanged({
                      dataField: "brandId",
                      value: e.itemData.brand_id,
                    })
                  }
                  items={[
                    { isNew: true, enableActiveState: false },
                    ...props.editorOptions.items,
                  ]}
                  itemRender={(data) => {
                    return !data.isNew ? (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <div>{data.brand_name}</div>
                        <Button
                          onClick={(e) =>
                            handleEdit(
                              e,
                              data.brand_id,
                              data.brand_name,
                              "Brand"
                            )
                          }
                        >
                          Edit
                        </Button>
                      </div>
                    ) : (
                      <Button
                        style={{
                          width: "100%",
                          height: "100%",
                          margin: "-7px -9px",
                        }}
                        onClick={(e) => handleAddNew(e, "Brand")}
                      >
                        Add New
                      </Button>
                    );
                  }}
                >
                  <Validator validationGroup="data">
                    <RequiredRule message={"Required"} />
                  </Validator>
                </SelectBox>
              );
            }}
            dataField={"brandId"}
            editorType={"dxSelectBox"}
            editorOptions={brandEditorOptions}
          >
            <Label visible={false} />
            <RequiredRule message={"Please chose a brand name"} />
          </Item>
        )}
        {showCategorySelector && (
          <Item
            render={(props) => {
              return (
                <SelectBox
                  valueExpr={"category_id"}
                  displayExpr={"category_name"}
                  value={categoryId}
                  onItemClick={(e) =>
                    fieldDataChanged({
                      dataField: "categoryId",
                      value: e.itemData.category_id,
                    })
                  }
                  items={[
                    { isNew: true, enableActiveState: false },
                    ...props.editorOptions.items,
                  ]}
                  itemRender={(data) => {
                    return !data.isNew ? (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <div>{data.category_name}</div>
                        <Button
                          onClick={(e) =>
                            handleEdit(
                              e,
                              data.category_id,
                              data.category_name,
                              "Category"
                            )
                          }
                        >
                          Edit
                        </Button>
                      </div>
                    ) : (
                      <Button
                        style={{
                          width: "100%",
                          height: "100%",
                          margin: "-7px -9px",
                        }}
                        onClick={(e) => handleAddNew(e, "Category")}
                      >
                        Add New
                      </Button>
                    );
                  }}
                >
                  <Validator validationGroup="data">
                    <RequiredRule message={"Required"} />
                  </Validator>
                </SelectBox>
              );
            }}
            dataField={"categoryId"}
            editorType={"dxSelectBox"}
            editorOptions={categoryEditorOptions}
            label={{ visible: false }}
          ></Item>
        )}
        <Item
          dataField={"barcode"}
          editorType={"dxTextBox"}
          editorOptions={barcodeEditorOptions}
          label={{ visible: false }}
        >
          <RequiredRule message={"Write a barcode"} />
        </Item>
        <Item
          dataField={"ozSize"}
          editorOptions={sizeEditorOptions}
          label={{ visible: false }}
        >
          <RequiredRule />
        </Item>
        <Item
          dataField={"price"}
          editorOptions={priceEditorOptions}
          label={{ visible: false }}
        >
          <RequiredRule />
        </Item>
        <Item
          render={(item) => renderImageUpload(item, image, handleRemoveImage)}
          dataField={"productImage"}
          label={{ visible: false }}
        />

        <ButtonItem>
          <ButtonOptions
            width={"100%"}
            type={"default"}
            useSubmitBehavior={true}
          >
            <span className="dx-button-text">
              {loading ? (
                <LoadIndicator width={"24px"} height={"24px"} visible={true} />
              ) : isEdit ? (
                "Update Product"
              ) : (
                "Create a new Product"
              )}
            </span>
          </ButtonOptions>
        </ButtonItem>
      </Form>
    </>
  );
};

const renderImageUpload = (data, image, handleRemoveImage) => {
  return (
    <div>
      <FileUploader
        selectButtonText={"Select photo"}
        labelText={""}
        accept={"image/*"}
        uploadMode={"useForm"}
        onValueChanged={(e) =>
          data.component.updateData(data.dataField, e.value)
        }
      />
      <div style={{ display: "flex", flexDirection: "row" }}>
        {image && <img height={"100px"} width={"100px"} src={image} />}
        {image && (
          <Button
            style={{ height: "20px", width: "20px" }}
            onClick={handleRemoveImage}
          >
            X
          </Button>
        )}
      </div>
    </div>
  );
};
