import React, { useEffect, useMemo, useState } from "react";
import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.css";

import { useHistory } from "react-router-dom";
import DataGrid, {
  Column,
  Pager,
  Paging,
  FilterRow,
  HeaderFilter,
  Scrolling,
  SearchPanel,
} from "devextreme-react/data-grid";
import CustomStore from "devextreme/data/custom_store";
import { baseAxios } from "../../utils/config";
import Button from "@mui/material/Button";

function addDollarSign(amount) {
  if (amount == "undefined" || amount == undefined || amount < 0.01) {
    amount = 0.0;
  }
  return `$${amount}`;
}

export default function ManageProducts() {
  const history = useHistory();

  const [isMissingInfo, setIsMissingInfo] = useState(false);
  const [count, setCount] = useState(0);

  const dataSource = useMemo(
    () =>
      new CustomStore({
        load: function () {
          return baseAxios.get("/allproducts").then((result) => {
            if (!result.data) return [];
            return result.data.data.map((x) => ({
              ...x,
              price: addDollarSign(x.price),
              ozWeight: (x.weight / 28.35).toFixed(2),
            }));
          });
        },
        key: ["product_id"],
      }),
    []
  );

  const dataSourceMissingInfo = useMemo(
    () =>
      new CustomStore({
        load: function () {
          return baseAxios.get("/allProductsMissingInfo").then((result) => {
            setCount(result.data?.data?.length);
            if (!result.data) return [];
            return result.data.data.map((x) => ({
              ...x,
              price: addDollarSign(x.price),
              ozWeight: (x.weight / 28.35).toFixed(2),
            }));
          });
        },
        key: ["product_id"],
      }),
    []
  );

  useEffect(() => {
    dataSourceMissingInfo.load();
  }, []);

  const handleRowClick = (e) => {
    const productId = e.data.product_id;

    history.push({
      pathname: "/product",
      search: `?productId=${productId}`,
    });
  };

  const handleAddNewProduct = () => {
    history.push({
      pathname: "/product",
    });
  };

  const onToolbarPreparing = (e) => {
    e.toolbarOptions.items.unshift({
      // 'before' positions the button before other toolbar items (to the left)
      // 'after' would place it after other items (to the right)
      location: "after",
      widget: "dxButton",
      options: {
        icon: "add",
        text: "Add New Product",
        onClick: handleAddNewProduct,
      },
    });
  };

  return (
    <React.Fragment>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <h2 className={"content-block"}>Products</h2>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          <Button onClick={() => setIsMissingInfo((prev) => !prev)}>
            {isMissingInfo ? "All Products" : "Products Missing Info"}
          </Button>
          {!isMissingInfo && (
            <div
              style={{
                backgroundColor: "red",
                borderRadius: "15px",
                height: "20px",
                width: "auto",
                padding: "0px 8px",
                alignContent: "center",
                textAlign: "center",
                color: "white",
                marginTop: "12px",
                marginRight: "12px",
              }}
            >
              {count}
            </div>
          )}
        </div>
      </div>
      {isMissingInfo ? (
        <DataGrid
          dataSource={dataSourceMissingInfo}
          onToolbarPreparing={onToolbarPreparing}
          className={"dx-card wide-card"}
          showBorders={true}
          onRowClick={handleRowClick}
          defaultFocusedRowIndex={0}
          columnAutoWidth={true}
          columnHidingEnabled={true}
          allowColumnResizing={true}
        >
          <Paging defaultPageSize={25} />
          <Pager showPageSizeSelector={true} showInfo={true} />
          <FilterRow visible={true} />

          <SearchPanel
            visible={true}
            highlightCaseSensitive={false}
            highlightSearchText={false}
            width="500px"
          />
          <HeaderFilter visible={true} />
          <Scrolling mode="standard" />
          <Column
            dataField="manufacturer"
            caption="Manufacturer"
            allowSearch={false}
          />
          <Column dataField="name" caption="Name" allowSearch={true} />
          <Column dataField="brand" caption="Brand" allowSearch={true} />
          <Column dataField="category" caption="Category" allowSearch={true} />
          <Column dataField="barcode" caption="Barcode" allowSearch={true} />
          <Column dataField="ozWeight" caption="Oz. Size" allowSearch={true} />
          <Column dataField="price" caption="Price" allowSearch={false} />
        </DataGrid>
      ) : (
        <DataGrid
          dataSource={dataSource}
          onToolbarPreparing={onToolbarPreparing}
          className={"dx-card wide-card"}
          showBorders={true}
          onRowClick={handleRowClick}
          defaultFocusedRowIndex={0}
          columnAutoWidth={true}
          columnHidingEnabled={true}
          allowColumnResizing={true}
        >
          <Paging defaultPageSize={25} />
          <Pager showPageSizeSelector={true} showInfo={true} />
          <FilterRow visible={true} />

          <SearchPanel
            visible={true}
            highlightCaseSensitive={false}
            highlightSearchText={false}
            width="500px"
          />
          <HeaderFilter visible={true} />
          <Scrolling mode="standard" />
          <Column
            dataField="manufacturer"
            caption="Manufacturer"
            allowSearch={false}
          />
          <Column dataField="name" caption="Name" allowSearch={true} />
          <Column dataField="brand" caption="Brand" allowSearch={true} />
          <Column dataField="category" caption="Category" allowSearch={true} />
          <Column dataField="barcode" caption="Barcode" allowSearch={true} />
          <Column dataField="ozWeight" caption="Oz. Size" allowSearch={true} />
          <Column dataField="price" caption="Price" allowSearch={false} />
        </DataGrid>
      )}
    </React.Fragment>
  );
}
