import React, { useState, useRef, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { SingleCard } from "../../layouts";
import { baseAxios } from "../../utils/config.js";
import { ProductForm } from "../../components/products/product-form.js";
import notify from "devextreme/ui/notify";
import { useHistory } from "react-router-dom";

export default function NewProduct() {
  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const formData = useRef({});

  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const productId = parseInt(queryParams.get("productId"), 10);
  const [currImage, setCurrImage] = useState();

  const [brands, setBrands] = useState([]);
  const [manufacturers, setManufacturers] = useState([]);
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        if (productId) {
          const resBrandsPromise = baseAxios.get(`/products/brands/all`);
          const resManufacturersPromsie = baseAxios.get(
            `/products/manufacturers/all`
          );
          const resCategoriesPromise = baseAxios.get(
            `/products/categories/all`
          );

          const resProductPromise = baseAxios.get(
            `/products/getById/${productId}`
          );
          const resBrands = await resBrandsPromise;
          setBrands(resBrands.data);
          const resManufacturers = await resManufacturersPromsie;
          setManufacturers(resManufacturers.data);
          const resCategories = await resCategoriesPromise;
          setCategories(resCategories.data);
          const resProduct = await resProductPromise;
          const product = resProduct.data;

          if (resProduct.data.product_image) {
            setCurrImage(resProduct.data.product_image);
          }

          const initialFormData = {
            name: product.product_name,
            manufacturerId: product.manufacturer_id,
            brandId: product.brand_id,
            barcode: product.product_upc_code,
            categoryId: product.category_id,
            ozSize: product.product_container_size / 22.53,
            price: product.product_cost,
            productImage: product.product_image,
            imageDeleted: false,
          };
          formData.current = initialFormData;
        }
      } catch (error) {
        console.error("Error fetching services: ", error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const productData = formData.current;
    try {
      if (
        !productData.name ||
        !productData.manufacturerId ||
        !productData.brandId ||
        !productData.barcode ||
        !productData.ozSize ||
        productData.ozSize <= 0 ||
        !productData.price ||
        productData.price <= 0
      ) {
        notify("All data is required!", "Error", 3000);
        return;
      }

      const payload = new FormData();
      payload.append("name", productData.name);
      payload.append("manufacturerId", productData.manufacturerId);
      payload.append("brandId", productData.brandId);
      payload.append("barcode", productData.barcode);
      payload.append("categoryId", productData.categoryId);
      payload.append("ozSize", productData.ozSize);
      payload.append("price", productData.price);

      if (typeof productData.productImage == typeof "") {
        payload.append("productImage", productData.productImage);
      } else {
        payload.append("productImage", productData.productImage?.[0]);
      }
      payload.append("imageDeleted", productData.imageDeleted);

      if (productId) {
        await baseAxios.put(`product/${productId}`, payload);
      } else {
        await baseAxios.post(`product`, payload);
      }
      history.goBack();
    } catch (e) {
      setLoading(false);
      if (e?.data?.error) {
        notify(e.data.error, "Error", 3000);
      } else {
        notify("Error saving product.", "Error", 3000);
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <div style={{ cursor: loading ? "progress" : "default" }}>
      <SingleCard title={productId ? "Edit Product" : "Add Product"}>
        <form className={"edit-service-form"} onSubmit={handleSubmit}>
          <ProductForm
            currImage={currImage}
            loading={loading}
            setLoading={setLoading}
            formData={formData}
            isEdit={!!productId}
            manufacturers={manufacturers}
            setManufacturers={setManufacturers}
            categories={categories}
            setCategories={setCategories}
            brands={brands}
            setBrands={setBrands}
          />
        </form>
      </SingleCard>
    </div>
  );
}
